$orange: var(--orange);

.full_news_container {
  min-height: 50vh;

  .post {

    .header {
      margin: 10px 15px 0 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;


      .more_button {
        height: 50px;
        width: 175px;
        background: $orange;
        border-radius: 15px;
        font-weight: normal;
        font-size: 18px;
        color: white;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid black;
          transition: 0.2s;
        }
      }

      .info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .category {
          color: $orange;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 16px;
          margin-right: 50px;
        }

        .date {
          color: #888;
          font-size: 16px;
        }
      }
    }

    .main_post {
      margin: 50px 20px 0 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .image {
        width: 50%;
        max-height: 45vh;
        object-fit: cover;
        border-radius: 8px;
      }

      .content {
        width: 50%;
        padding: 16px;

        .header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;

          .category {
            color: $orange;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 16px;
          }

          .date {
            color: #888;
            font-size: 16px;
          }
        }

        .title {
          font-size: 24px;
          margin: 0 0 8px 0;
          font-weight: bold;
        }

        .description {
          font-size: 18px;
        }
      }
    }
  }
}
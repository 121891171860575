$orange: var(--orange);

.services {
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-family: var(--font-Inter), sans-serif;

  .section_title {
    font-size: 46px;
    margin: 25px 0 10px 0;

    span {
      color: $orange;
    }
  }

  .service_list {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }
}


.service_card {
  border: 1px solid #ddd;
  border-radius: 15px;
  background: white;
  max-width: 350px;
  margin: 15px;
  box-shadow: 0 10px 28px -8px rgba(0, 0, 0, 0.25);

  .title {
    text-align: left;
    font-size: 24px;
    height: 55px;
    margin: 5px 0 0 10px;
    padding: 0;
  }

  .description {
    text-align: left;
    font-size: 16px;
    margin: 10px 0 0 10px;
    height: 75px;
    overflow: hidden;
    padding: 0;
  }

  .read_more_button {
    float: left;
    margin: 0 0 10px 10px;
    padding: 6px 12px;
    background-color: transparent;
    border: 1px solid #888;
    border-radius: 4px;
    color: #888;
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.2s;

    &:hover {
      background-color: $orange;
      border-color: #000;
      color: white;
    }

    span {
      text-decoration: none;
      color: #888;
    }
  }
}

@media only screen and (min-width: 901px) {
}

@media only screen and (max-width: 900px) and (min-width: 764px) {
}

@media only screen and (max-width: 763px) {
  .service_card {
    border: 1px solid #ddd;
    border-radius: 15px;
    max-width: 40%;
    margin: 15px;
    box-shadow: 0 10px 28px -8px rgba(0, 0, 0, 0.25);

    .title {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      font-size: 20px;
      max-height: 75px;
    }

    .description {
      font-size: 14px;
      height: 100px;
    }

    .button_container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 5px;

      .read_more_button {
        margin: 0;
        font-size: 12px;
        width: 90%;
        text-wrap: nowrap;
      }
    }
  }
}
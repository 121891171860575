$font-family: var(--font-Inter), sans-serif;
$orange: var(--orange);
$button-width: 175px;
$button-height: 50px;
$button-border-radius: 15px;
$button-font-size: 18px;

.team_section {
  margin-top: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-family: $font-family;

  .section_title {
    font-size: 52px;
    margin: 25px 0 10px;

    span {
      color: $orange;
    }
  }

  .section_description {
    font-size: 16px;
    margin: 0 100px;
  }

  .team_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .arrow_image {
      height: 100%;
    }

    .buttons {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      .team_button {
        height: $button-height;
        width: $button-width;
        background: white;
        border-radius: $button-border-radius;
        border: 1px solid $orange;
        font-weight: normal;
        font-size: $button-font-size;
        color: $orange;
        transition: 0.2s;

        &:hover, &.active {
          background: $orange;
          color: white;
        }
      }
    }
  }

  .employee_grid {
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 5%;
    flex-direction: row;
    flex-wrap: wrap;

    .employee_item {
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-position: center;
      position: relative;
      overflow: hidden;
      margin-bottom: 15px;

      .employee_info {
        width: 90%;
        position: absolute;
        bottom: 0;
        z-index: 2;
        color: white;
        background-color: rgba(0, 0, 0, 0.4);
        text-align: center;
        margin-bottom: 15px;
        border-radius: 15px;
        padding: 6px 0;

        p {
          margin: 0;
          font-size: 18px;
        }

        .name {
          font-weight: bolder;
        }

        .role {
          color: #8D8D8D;
        }
      }
    }
  }
}

@media only screen and (min-width: 901px) {
  .team_section {
    margin-top: 40px;

    .team_content .arrow_image {
      width: 45%;
    }

    .team_content .buttons {
      width: 52.5%;
    }
  }

  .employee_grid {
    .employee_item {
      height: 400px;
      width: 275px;
    }
  }
}

@media only screen and (max-width: 900px) and (min-width: 721px) {
  .team_section {
    margin-top: 40px;

    .team_content {
      .arrow_image {
        width: 45%;
      }

      .buttons {
        width: 52.5%;
      }
    }

    .employee_grid {
      .employee_item {
        height: 400px;
        width: 275px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .team_content {
    .arrow_image {
      width: 47.5%;
    }

    .buttons {
      gap: 10px;
    }
  }

  .employee_grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    gap: 16px;

    .employee_item {
      height: 300px;
      min-width: 175px;

      .employee_info{
        .role{
          font-size: small;
        }
      }
    }
  }
}

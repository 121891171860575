.banner {
  margin: 0;
  padding: 0;
  width: 100%;
}

.important_info_container {
  width: 100%;
  font-family: var(--font-Inter), sans-serif;
  display: flex;
  box-shadow: 0 10px 28px -8px rgba(0, 0, 0, 0.25);
  background: white;

  .info_container {
    display: flex;
    align-items: center;
    width: 100%;

    .icon {
      height: 40px;
      aspect-ratio: 1 / 1;
    }

    .text_container {
      display: flex;
      flex-direction: column;

      label, p {
        font-size: 18px;
      }

      p {
        font-weight: 600;
        margin: 0;
      }
    }
  }
}

@media only screen and (min-width: 901px) {
  .important_info_container {
    height: 100px;

    .info_container {
      justify-content: center;

      .text_container {
        padding-left: 10px;
      }
    }
  }
}

@media only screen and (max-width: 900px) and (min-width: 721px) {
  .important_info_container {
    padding-top: 15px;
    justify-content: space-between;

    .info_container {
      margin-left: 25px;
      flex-direction: column;

      .text_container {
        text-align: center;
        justify-content: center;
        max-width: 225px;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .banner {
    display: none;
  }
}

@media only screen and (max-width: 720px) {
  .important_info_container {
    padding-top: 15px;
    flex-direction: column;
    justify-content: center;

    .info_container {
      flex-direction: column;

      .text_container {
        margin: 10px 0;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

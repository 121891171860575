$orange: var(--orange);

.card {
  min-width: 275px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  max-width: 300px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  .image {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  .content {
    padding: 16px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .category {
        color: $orange;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
      }

      .date {
        color: #888;
        font-size: 12px;
      }
    }

    .title {
      text-align: left;
      font-size: 18px;
      margin: 0 0 8px 0;
      font-weight: bold;
    }

    .description {
      height: 100px;
      line-height: 20px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -ms-box-orient: vertical;
      box-orient: vertical;
      -webkit-line-clamp: 5;
      -moz-line-clamp: 5;
      -ms-line-clamp: 5;
      line-clamp: 5;
      overflow: hidden;
    }

    .read_more_button {
      float: left;
      margin: 0 0 10px 10px;
      padding: 6px 12px;
      background-color: transparent;
      border: 1px solid #888;
      border-radius: 4px;
      color: #888;
      font-size: 12px;
      cursor: pointer;
      text-transform: uppercase;
      transition: 0.2s;

      &:hover {
        background-color: $orange;
        border-color: #000;
        color: white;
      }

      span {
        text-decoration: none;
        color: #888;
      }
    }
  }
}
$orange: var(--orange);

.jobDetail {
  width: 70%;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto;

  h2 {
    margin-bottom: 10px;
    font-size: 1.8em;
  }

  p {
    margin: 5px 0;
    font-size: 1em;
    color: #555;
  }

  button {
    padding: 10px 20px;
    margin-bottom: 20px;
    background-color: $orange;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $orange;
      opacity: 0.8;
    }
  }
}

.sectionHeader {
  margin-top: 20px;
  font-size: 1.4em;
  color: #333;
}

.skillList,
.benefitList {
  padding-left: 20px;
  margin-bottom: 20px;
  list-style: none;

  li {
    margin-bottom: 5px;
    font-size: 1em;
    color: #444;
  }
}

@media only screen and (min-width: 764px) {
  .mobile_back_button {
    display: none;
  }
}

@media only screen and (max-width: 763px) {
  .jobDetail {
    width: 100vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
  }
}
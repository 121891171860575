@media screen and (min-width: 1200px) {
  .header_section {
    padding: 15px 0;
    box-shadow: 0 10px 28px -8px rgba(0, 0, 0, 0.25);
    height: auto;

    .desktop_nav {
      font-family: var(--font-Inter), sans-serif;
      margin: 5px 25px 0 0;
      display: flex;
      felx-direction: row;
      justify-content: space-between;
      align-items: center;

      .left_container {
        display: flex;

        .logo_container {
          display: flex;
          align-items: center;
        }

        .control_container {
          margin-left: 25px;
          font-size: 28px;
          display: flex;
          align-items: center;
          flex-direction: row;
          list-style: none;
          padding: 0;

          .menu_item {
            margin: 0 20px;
            text-decoration: none;
            color: black;
            font-size: 26px;

            &:hover {
              color: var(--orange);
              cursor: pointer;
              text-decoration: underline 1px solid var(--orange);
            }
          }
        }
      }

      .controls {
        display: flex;

        .search_bar_container {
          position: relative;
          width: 100%;

          .search_bar {
            width: 100%;
            box-sizing: border-box;
            height: 50px;
            border-radius: 15px;
            padding: 0 40px 0 10px;
            font-size: 18px;
            border: 2.5px solid var(--orange);
            background-color: white;
            color: black;

            &:focus {
              outline: none;
            }

            &:hover {
              background: #e3e3e3;
              transition: 0.2s;
            }
          }

          .search_icon {
            color: black;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            margin-right: 10px;
          }
        }

        .round_contact_button {
          margin-left: 25px;
          background: transparent;
          border-radius: 50px;
          height: 45px;
          aspect-ratio: 1 / 1;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--orange);
          border: 2.5px solid var(--orange);

          &:hover {
            cursor: pointer;
            background: var(--orange);
            color: white;
            transition: 0.2s;
          }
        }
      }
    }

    .mobile_nav {
      display: none;
    }
  }
}

@media screen and (max-width: 1199px) {
  .header_section {
    box-shadow: 0 10px 28px -8px rgba(0, 0, 0, 0.25);
    z-index: 999;
    background: #ebebeb;
    width: 100%;
    height: 100px;

    .desktop_nav {
      display: none;
    }

    .mobile_nav {
      display: flex;
      align-items: center;

      .logo_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        margin-left: 20px;
      }

      .logo {
        height: 90px;
        width: 90px;
      }

      .controls {
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        .search_bar_container {
          position: relative;
          width: 100%;

          .search_bar {
            width: 100%;
            box-sizing: border-box;
            height: 50px;
            border-radius: 15px;
            padding: 0 40px 0 10px;
            font-size: 18px;
            border: 2.5px solid var(--orange);
            background-color: transparent;
            color: white;

            &:focus {
              background: #e3e3e3;
              outline: none;
            }

            &:hover {
              background: #e3e3e3;
              transition: background 0.2s;
            }
          }

          .search_icon {
            color: black;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            margin-right: 10px;
          }
        }
      }
    }

    .banner {
      display: none;
    }
  }
}

.control_container {
  list-style: none;
  padding: 0;
  margin: 0;

  .menu_item {
    text-decoration: none;
    color: white;
    font-size: 26px;

    &:hover {
      color: var(--orange);
      cursor: pointer;
      text-decoration: underline 1px solid var(--orange);
    }
  }
}

$orange: var(--orange);

.main_post {
  background: #eaeaea;
  margin: 50px 20px 0 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;

  .image {
    margin-left: 25px;
    width: 35%;
    max-height: 35vh;
    object-fit: cover;
    border-radius: 8px;
  }

  .content {
    width: 50%;
    padding: 16px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .category {
        color: $orange;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
      }

      .date {
        color: #888;
        font-size: 16px;
      }
    }

    .title {
      font-size: 24px;
      margin: 0 0 8px 0;
      font-weight: bold;
    }

    .description {
      font-size: 18px;
    }
  }

  // Media query for mobile responsiveness
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 20px;

    .image {
      margin-left: 0;
      width: 90%;
      max-height: 50vh;
      margin-bottom: 15px;
    }

    .content {
      width: 90%;
      padding: 12px;

      .title {
        font-size: 22px;
      }

      .description {
        font-size: 16px;
      }

      .header {
        flex-direction: column;
        align-items: flex-start;

        .category, .date {
          font-size: 14px;
        }

        .category {
          margin-bottom: 4px;
        }
      }
    }
  }
}

$orange: var(--orange);

.content_section {
  width: 100%;

  p {
    margin: 0;
    padding: 7.5px 25px 7.5px 20px;
    text-align: start;
    font-size: 25px;
  }

  h1 {
    text-align: center;
  }

  .section_title {
    font-size: 52px;
    margin: 25px 0;

    span {
      color: $orange;
    }
  }

  .text_section {
    .wrap {
      @media screen and (min-width: 1250px) {
        float: left;
      }

      @media screen and (max-width: 1249px) {
        display: block;
        text-align: center;
      }

      .img_container {
        margin-left: 25px;
        padding-right: 25px;
        position: relative;
        border-radius: 15px;

        @media screen and (min-width: 1250px) {
          width: 600px;
          height: 450px;
          margin-right: 60px;
        }

        @media screen and (max-width: 1249px) {
          margin: 0 auto 20px auto;
          width: auto;
          height: auto;
          padding-right: 0;
        }

        .background {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          background: #232323;
          border-radius: 15px;

          @media screen and (max-width: 1249px) {
            display: none;
          }
        }

        img {
          position: relative;
          z-index: 1;
          border-radius: 15px;

          @media screen and (min-width: 1250px) {
            height: 410px;
            margin: 20px 0 0 35px;
          }

          @media screen and (max-width: 1249px) {
            height: auto;
            width: 100%;
            margin: 0;
          }
        }
      }
    }

    .text {
      @media screen and (min-width: 1250px) {
        padding: 0 50px;
      }

      @media screen and (max-width: 1249px) {
        padding: 0;
      }

      .headline {
        font-size: 32px;
        font-weight: bolder;

        @media screen and (min-width: 1250px) {
          margin: 0 0 25px 0;
        }

        @media screen and (max-width: 1249px) {
          margin: 0;
        }
      }
    }
  }
}

$orange: var(--orange);

.about_us_section {
  margin-top: 75px;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-family: var(--font-Inter), sans-serif;

  .section_title {
    font-size: 52px;
    margin: 25px 0 10px 0;

    span {
      color: $orange;
    }
  }

  .content_container {
    display: flex;
    margin: 0 125px;

    .img_container {
      width: 600px;
      height: 450px;
      position: relative;
      border-radius: 15px;
      margin-right: 60px;

      .background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background: #232323;
        border-radius: 15px;
      }

      img {
        height: 410px;
        position: relative;
        z-index: 1;
        border-radius: 15px;
        margin: 20px 0 0 35px;
      }
    }

    .text_container {
      margin-left: 50px;
      text-align: left;

      .headline {
        margin: 0;
        font-size: 32px;
        font-weight: bolder;
      }

      .info_content {
        font-size: 24px;
      }

      .more_button {
        height: 50px;
        width: 175px;
        background: $orange;
        border-radius: 15px;
        font-weight: normal;
        font-size: 18px;
        color: white;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid black;
          transition: 0.2s;
        }
      }
    }
  }

  @media only screen and (max-width: 1470px) and (min-width: 1251px) {
    .content_container {
      margin: 0 50px;

      .text_container {
        margin: 15px 15px 0 25px;
        text-align: center;
      }
    }
  }


  @media only screen and (max-width: 1250px) and (min-width: 721px) {
    .content_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .img_container {
        margin-left: -50px;
      }

      .text_container {
        margin: 15px 25px 0 25px;
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 720px) {
    .content_container {
      flex-direction: column;
      align-items: center;
      margin: 0;

      .img_container {
        display: none;
      }

      .text_container {
        margin: 0;
        padding: 0 30px;
        text-align: left;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .headline {
          text-align: center;
        }

        .info_content {
          text-align: center;
        }

        .button_container {
          display: flex;
          justify-content: center;

          .more_button {
            width: 225px;
          }
        }
      }
    }
  }
}

$orange: var(--orange);

.job_section {
  margin: 0;
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  font-family: var(--font-Inter), sans-serif;
  min-height: 35vh;

  .section_title {
    font-size: 46px;
    margin: 25px 0 10px 0;

    span {
      color: $orange;
    }
  }
}

.app_container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}


.applyText {
  font-size: 1em;
  color: #333;
  margin-top: 20px;
  font-weight: bold;
}

@media only screen and (min-width: 764px) {
  .app_container {
    display: flex;
  }
  .mobile_app_container {
    display: none;
  }

  .section_title {
    display: block;
  }

  .mobile_header {
    display: none;
  }
}

@media only screen and (max-width: 763px) {
  .mobile_app_container {
    display: block;
  }
  .app_container {
    display: none;
  }

  .section_title {
    display: none;
  }

  .mobile_header {
    display: flex;
    justify-content: center;

    .mobile_title {
      font-size: 46px;
      margin: 25px 0 10px 0;

      span {
        color: $orange;
      }
    }

    .mobile_back_button {
      display: block;
      width: 325px;
      padding: 12px 0;
      cursor: pointer;
      border-radius: 15px;
      background-color: $orange;
      color: #fff;
      font-size: 22px;
      transition: background-color 0.3s;
      align-self: center;
      border: 1px transparent solid;
      margin-top: 30px;

      &:hover {
        border: 1px solid black;
      }

      span {
        font-size: 18px;
      }
    }
  }
}
.detail_service{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;

  .headline{
    text-align: center;
    font-size: 48px;
  }

  .text_container{
    text-align: center;
    max-width: 60vw;
    p{
      margin: 15px 0;
      padding: 0;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
$orange: var(--orange);

.popup {
  width: 400px;
  background: #fff;
  border-radius: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 30px;
  color: #333;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  transition: visibility 0s, opacity 0.4s ease-in-out;

  h2 {
    font-size: 38px;
    font-weight: 500;
    margin: 30px 0 10px;
  }

  button {
    width: 100%;
    margin-top: 50px;
    padding: 10px 0;
    background-color: $orange;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 18px;
    border-radius: 15px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.2);
    cursor: pointer;
  }
}

.popup.show {
  visibility: visible;
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.4s ease-in-out;
}

.overlay.show {
  visibility: visible;
  opacity: 1;
}
$orange: var(--orange);

.service_section {
  margin: 0 0 100px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-family: var(--font-Inter), sans-serif;

  .section_title {
    font-size: 52px;
    margin: 75px 0 10px 0;

    span {
      color: $orange;
    }
  }

  .section_description {
    font-size: 16px;
    margin: 0 100px;
  }

  .services_grid_box {
    margin: 15px 150px 0 150px;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);

    .service_grid_item {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f9f9f9;

      .icon {
        height: 50px;
        font-size: 24px;
        margin-bottom: 8px;
      }

      span {
        font-size: 22px;
        margin: 10px 0 10px 5px;
      }
    }
  }

  .more_info_button {
    width: 325px;
    padding: 12px 0;
    cursor: pointer;
    border-radius: 15px;
    background-color: $orange;
    color: #fff;
    font-size: 22px;
    transition: background-color 0.3s;
    align-self: center;
    border: 1px transparent solid;
    margin-top: 30px;

    &:hover {
      border: 1px solid black;
    }

    span {
      font-size: 18px;
    }
  }
}

@media only screen and (min-width: 901px) {
  .service_section {
    margin-top: 25px;

    .service_section .services_grid_box {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media only screen and (max-width: 900px) and (min-width: 721px) {
  .service_section .services_grid_box {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 25px;
  }
}

@media only screen and (max-width: 720px) {
  .service_section .services_grid_box {
    display: flex;
    flex-direction: column;
  }
}

$orange: var(--orange);

.not_found_container{
  bottom: 0;
  height: 56.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  p {
    font-size: 20px;
  }

  button {
    height: 50px;
    background: $orange;
    border-radius: 15px;
    font-weight: normal;
    font-size: 18px;
    color: white;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid black;
      transition: 0.2s;
    }
  }
}


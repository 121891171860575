$orange: var(--orange);

.news_section {
  margin: 25px 0 0 0;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-family: var(--font-Inter), sans-serif;

  .section_title {
    font-size: 52px;
    margin: 25px 0 10px 0;

    span {
      color: $orange;
    }
  }

  .post_container{
    text-align: left;
  }

  .card_grid {
    margin: 0 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 25px;

    .error_msg {
      font-size: 22px;
      margin: 25px;
      width: 100vw;
      text-align: center;
    }
  }

  .more_info_button {
    width: 325px;
    padding: 12px 0;
    cursor: pointer;
    border-radius: 15px;
    background-color: $orange;
    color: #fff;
    font-size: 22px;
    transition: background-color 0.3s;
    align-self: center;
    border: 1px transparent solid;
    margin-top: 30px;

    &:hover {
      border: 1px solid black;
    }

    span {
      font-size: 18px;
    }
  }
}

@media only screen and (min-width: 901px) {
  .card_grid {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 900px) and (min-width: 721px) {
}

@media only screen and (max-width: 720px) {
  .card_grid {
    overflow: auto;
    flex-wrap: wrap;
  }
}



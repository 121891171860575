$orange: var(--orange);

.contact_page {
  text-align: center;
  height: 56.5vh;

  .section_title {
    font-size: 52px;

    span {
      color: $orange;
    }
  }

  .section_description {
    font-size: 16px;
  }

  .box {
    width: 300px;
    margin: 15px auto;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 15px;
    background-color: #f5f5f5;

    h2 {
      margin-top: 0;
      text-align: center;
    }

    ul {
      padding-left: 20px;
      list-style: none;

      li {
        margin-bottom: 5px;
      }
    }
  }
}
$orange: var(--orange);

.link{
  color: #BFBFBF;
  text-decoration: none;
  &:hover{
    text-decoration: 1.5px underline solid $orange;
  }
}

@media only screen and (min-width: 1400px) {
  footer {
    margin-top: 85px;
    background-color: #232323;
    width: 100%;
    font-family: var(--font-Inter), sans-serif;

    .upper_footer {
      padding-top: 45px;
      margin: 0 60px;
      display: flex;
      justify-content: space-between;

      .left_side {
        display: flex;
        flex-direction: column;

        .icon {
          display: flex;
          justify-content: left;
          width: 275px;
          height: 100px;
        }

        .company_description {
          font-size: 18px;
          width: 550px;
          color: white;
        }
      }

      .right_side {
        width: 60%;
        justify-content: end;
        display: flex;
        flex-direction: row;
        gap: 5%;

        .footer_info_container {
          display: flex;
          flex-direction: column;

          h3 {
            color: white;
            font-size: 28px;
            margin-bottom: 10px;
          }

          ul {
            display: flex;
            flex-direction: column;
            font-size: 20px;
            color: #BFBFBF;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              margin: 5px 0;
            }
          }
        }
      }
    }

    .divider_container {
      margin-top: 50px;
      display: flex;
      width: 100%;
      justify-content: center;

      .divider {
        height: 1px;
        width: 95%;
        background-color: white;
      }
    }

    .lower_footer {
      display: flex;
      justify-content: space-between;
      padding: 0 60px;
      color: white;
      font-size: 18px;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 1399px) and (min-width: 875px) {
  footer {
    margin-top: 85px;
    background-color: #232323;
    width: 100%;
    font-family: var(--font-Inter), sans-serif;

    .upper_footer {
      padding-top: 45px;
      margin: 0 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .left_side {
        min-width: 275px;
        display: flex;
        align-items: center;
        flex-direction: row;

        .icon {
          display: flex;
          justify-content: left;
          width: 275px;
          height: 100px;
        }

        .company_description {
          margin: 0 0 0 50px;
          font-size: 18px;
          color: white;
        }
      }

      .right_side {
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5%;

        .footer_info_container {
          display: flex;
          flex-direction: column;

          h3 {
            color: white;
            font-size: 28px;
            margin-bottom: 10px;
          }

          ul {
            display: flex;
            flex-direction: column;
            font-size: 20px;
            color: #BFBFBF;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              margin: 5px 0;
            }
          }
        }
      }
    }

    .divider_container {
      margin-top: 50px;
      display: flex;
      width: 100%;
      justify-content: center;

      .divider {
        height: 1px;
        width: 95%;
        background-color: white;
      }
    }

    .lower_footer {
      display: flex;
      justify-content: space-between;
      padding: 0 60px;
      color: white;
      font-size: 18px;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 874px) {
  footer {
    margin-top: 85px;
    background-color: #232323;
    width: 100%;
    font-family: var(--font-Inter), sans-serif;

    .upper_footer {
      padding-top: 45px;
      display: flex;
      flex-direction: column;

      .left_side {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        align-items: center;

        .icon {
          width: 275px;
          height: 100px;
        }

        .company_description {
          font-size: 18px;
          width: 100%;
          color: white;
        }
      }

      .right_side {
        width: 100%;
        display: flex;
        flex-direction: column;

        .footer_info_container {
          width: 100%;
          display: flex;
          text-align: center;
          flex-direction: column;
          h3 {
            color: white;
            font-size: 28px;
            margin-bottom: 10px;
          }

          ul {
            display: flex;
            flex-direction: column;
            font-size: 20px;
            color: #BFBFBF;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              margin: 5px 0;
            }
          }
        }
      }
    }

    .divider_container {
      margin-top: 50px;
      display: flex;
      width: 100%;
      justify-content: center;

      .divider {
        height: 1px;
        width: 95%;
        background-color: white;
      }
    }

    .lower_footer {
      text-align: center;
      display: flex;
      flex-direction: column;
      color: white;
      font-size: 18px;
      margin: 0;
    }
  }
}
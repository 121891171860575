$orange: var(--orange);

.lower_content {
  margin: 50px 25px 0 25px;
  font-size: 20px;

  .timeline {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }
}

@media only screen and (min-width: 901px) {
  .card {
    min-width: 200px;
  }

  .timeline {
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

}

@media only screen and (max-width: 900px) and (min-width: 721px) {
  .card {
    min-width: 200px;
  }

  .timeline {
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 720px) {
  .card {
    min-width: 250px;
  }

  .timeline {
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

  .main_post {
    .header {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
}
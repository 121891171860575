$orange: var(--orange);

.jobList {
  width: 30%;
  border-right: 1px solid #ccc;
  padding: 10px;
  background-color: #f7f7f7;
  overflow-y: auto;

  .jobCard {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    margin-bottom: 15px;
    padding: 10px;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e6f7ff;
    }

    h3 {
      margin: 0;
      font-size: 1.2em;
    }

    p {
      margin: 5px 0;
      color: #666;
      font-size: 0.9em;
    }
  }
}

@media only screen and (min-width: 764px) {
  .app_container {
    display: flex;
  }
  .mobile_app_container {
    display: none;
  }
}

@media only screen and (max-width: 763px) {
  .jobList {
    width: 100vw;
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    flex-direction: column;

    .jobCard{
     width: 90vw;
    }
  }
}
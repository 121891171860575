.imprint_sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
  padding: 0 50px;

  h1 {
    font-size: 48px;
    text-align: center;
    margin: 25px 0;
  }

  .headline_1 {
    font-size: 32px;
    font-weight: bold;
    margin: 0 0 10px 0;
    padding: 0;
  }

  .headline_2 {
    font-size: 24px;
    font-weight: bolder;
    padding: 0;
    margin: 0 0 10px 0;
  }

  .content_block {
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    padding: 0;
    margin: 0 0 20px 0;
  }
}
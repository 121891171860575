.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
  background: white;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #232323;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;

  .bm-morph-shape {
    fill: #373a47;
  }

  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;

    .bm-item {
      display: inline-block;
      text-decoration: none;
      margin-bottom: 10px;
      color: #d1d1d1;
      transition: color 0.2s;

      &:hover {
        color: white;
      }
    }
  }
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
